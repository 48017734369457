<template>
  <CreateUpdateTemplate
    :routePreventDialog="routePreventDialog"
    :customClass="'customer-create create-form'"
    v-if="getPermission('customer:create')"
  >
    <template v-slot:body>
      <div class="bg-white px-8">
        <v-form
          ref="createQuotationForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="validateJob()"
        >
          <v-row>
            <v-col md="12">
              <div class="form-action">
                <div class="form-title">
                  Sale for

                  <!-- <span>
                    <v-autocomplete
                        hide-details
                        class="form-title-link cursor-pointer"
                        id="customer-equipment"
                        :items="customerList"
                        dense
                        flat
                        filled
                        label="Customer"
                        :rules="[
                          validateRules.required(
                            dbQuotation.customer,
                            'customer'
                          ),
                        ]"
                        item-color="cyan"
                        color="cyan"
                        solo
                        v-model.trim="dbQuotation.customer"
                        item-text="display_name"
                        item-value="id"
                        v-on:change="selectCustomer()"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title
                              v-html="'No Customer(s) Found.'"
                            ></v-list-item-title>
                          </v-list-item>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list-item-avatar>
                            <v-img
                              :lazy-src="$defaultProfileImage"
                              :src="
                                $assetAPIURL(
                                  item.profile_logo
                                )
                              "
                              aspect-ratio="1"
                              class="margin-auto"
                              transition="fade-transition"
                            ></v-img>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-capitalize font-weight-500 font-size-16"
                              >{{
                                item.display_name
                              }}</v-list-item-title
                            >
                            <v-list-item-title
                              class="text-capitalize font-weight-500 font-size-16"
                              >{{
                                item.company_name
                              }}</v-list-item-title
                            >
                          </v-list-item-content>
                          <v-list-item-action
                            v-if="item.default_person"
                            class="align-self-center"
                          >
                            <v-list-item-subtitle
                              class="text-lowercase pb-2 font-weight-500 font-size-14"
                              >{{
                                item.default_person
                                  .primary_email
                              }}</v-list-item-subtitle
                            >
                            <v-list-item-subtitle
                              class="text-lowercase font-weight-500 font-size-14"
                              >{{
                                item.default_person
                                  .primary_phone
                              }}</v-list-item-subtitle
                            >
                          </v-list-item-action>
                        </template>
                      </v-autocomplete>
                  </span> -->

                  <span
                    class="form-title-link cursor-pointer"
                    v-on:click="openDialog('customer')"
                  >
                    <template v-if="vCustomerId">{{
                      vCustomer.display_name
                    }}</template>
                    <template v-else>
                      Select a Customer
                      <v-icon large color="cyan"
                        >mdi-plus-circle-outline</v-icon
                      >
                    </template>
                  </span>
                </div>
                <div>
                  <v-btn
                    :disabled="formLoading"
                    v-on:click="goBack()"
                    class="custom-bold-button custom-grey-border"
                    depressed
                    >Cancel</v-btn
                  >
                  <v-btn
                    class="custom-bold-button ml-4"
                    depressed
                    :disabled="!formValid || formLoading"
                    :loading="formLoading"
                    v-on:click="validateJob()"
                    color="cyan white--text"
                    >Save</v-btn
                  >
                </div>
              </div>
            </v-col>
            <v-col md="7">
              <label for="visit-title" class="required">Sale Title</label>
              <v-text-field
                id="visit-title"
                dense
                filled
                placeholder="Title"
                solo
                flat
                v-model="dbQuotation.title"
                color="cyan"
                :maxlength="250"
                :rules="[validateRules.required(dbQuotation.title, 'Title')]"
              />
              <v-textarea
                auto-grow
                dense
                filled
                color="cyan"
                v-model="dbQuotation.description"
                placeholder="Description"
                solo
                flat
                row-height="25"
                :rules="[
                  validateRules.minLength(
                    dbQuotation.description,
                    'Description',
                    1
                  ),
                  validateRules.maxLength(
                    dbQuotation.description,
                    'Description',
                    1024
                  ),
                ]"
                v-on:keypress="(e) => manageLimitdescr(e)"
                v-on:paste="(e) => onPaste(e)"
              />
              <v-col md="12" class="text-right"
                >{{
                  dbQuotation.description ? dbQuotation.description.length : 0
                }}/200</v-col
              >
              <v-row>
                <v-col md="6">
                  <v-layout class="align-center">
                    <v-flex md3
                      ><label class="my-0">Billing Location</label></v-flex
                    >
                    <v-flex md9 class="position-relative">
                      <v-icon
                        v-on:click="openDialog('billing')"
                        small
                        class="py-0 my-0"
                        color="cyan"
                        style="position: absolute; top: -8px"
                        >mdi-pencil</v-icon
                      >
                    </v-flex>
                  </v-layout>
                  <template v-if="vBilling?.id">
                    <p class="mb-0">{{ vBilling.property_address }}</p>
                  </template>
                  <template v-else-if="vCustomer?.id">
                    <p class="mb-0 red--text">Please select billing location</p>
                  </template>
                  <template v-else>
                    <p class="mb-0 red--text">Please select customer</p>
                  </template>
                  <v-layout class="mt-3 align-center">
                    <v-flex md3
                      ><label class="my-0">Contact Details</label></v-flex
                    >
                    <v-flex md9 class="position-relative">
                      <v-icon
                        small
                        v-on:click="openDialog('billing-contact')"
                        class="py-0 my-0"
                        color="cyan"
                        style="position: absolute; top: -8px"
                        >mdi-pencil</v-icon
                      >
                    </v-flex>
                  </v-layout>
                  <template v-if="vBillingContact?.id">
                    <p class="mb-0">{{ vBillingContact.display_name }}</p>
                    <p class="mb-0">{{ vBillingContact.primary_phone }}</p>
                    <p class="mb-0">{{ vBillingContact.primary_email }}</p>
                  </template>
                  <template v-else-if="vCustomer?.id">
                    <p class="mb-0 red--text">Please select contact person</p>
                  </template>
                  <template v-else>
                    <p class="mb-0 red--text">Please select customer</p>
                  </template>
                  <v-layout class="mt-3">
                    <v-flex md4
                      ><label class="my-0">Email Notification </label></v-flex
                    >
                    <v-flex md8>
                      <v-switch
                        v-model="dbQuotation.notify_billing_contact_person"
                        class="m-0 p-0"
                        color="cyan"
                        dense
                        inset
                      />
                    </v-flex>
                  </v-layout>
                </v-col>
                <v-col md="6">
                  <v-layout class="align-center">
                    <v-flex md3
                      ><label class="my-0">Site Location</label></v-flex
                    >
                    <v-flex md9 class="position-relative">
                      <v-icon
                        small
                        v-on:click="openDialog('property')"
                        class="py-0 my-0"
                        color="cyan"
                        style="position: absolute; top: -8px"
                        >mdi-pencil</v-icon
                      >
                    </v-flex>
                  </v-layout>

                  <template v-if="vProperty?.id">
                    <p class="mb-0">{{ vProperty.property_address }}</p>
                  </template>
                  <template v-else-if="vCustomer?.id">
                    <p class="mb-0 red--text">Please select service location</p>
                  </template>
                  <template v-else>
                    <p class="mb-0 red--text">Please select customer</p>
                  </template>

                  <v-layout class="mt-3 align-center">
                    <v-flex md3
                      ><label class="my-0">Contact Details</label></v-flex
                    >
                    <v-flex md9 class="position-relative">
                      <v-icon
                        small
                        v-on:click="openDialog('property-contact')"
                        class="py-0 my-0"
                        color="cyan"
                        style="position: absolute; top: -8px"
                        >mdi-pencil</v-icon
                      >
                    </v-flex>
                  </v-layout>

                  <template v-if="vPropertyContact?.id">
                    <p class="mb-0">{{ vPropertyContact.display_name }}</p>
                    <p class="mb-0">{{ vPropertyContact.primary_phone }}</p>
                    <p class="mb-0">{{ vPropertyContact.primary_email }}</p>
                  </template>
                  <template v-else-if="vCustomer?.id">
                    <p class="mb-0 red--text">Please select contact person</p>
                  </template>
                  <template v-else>
                    <p class="mb-0 red--text">Please select customer</p>
                  </template>

                  <v-layout class="mt-3">
                    <v-flex md4
                      ><label class="my-0">Email Notification </label></v-flex
                    >
                    <v-flex md8>
                      <v-switch
                        v-model="dbQuotation.notify_property_contact_person"
                        class="m-0 p-0"
                        color="cyan"
                        dense
                        inset
                      />
                    </v-flex>
                  </v-layout>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="5">
              <table width="100%" style="table-layout: fixed">
                <tr>
                  <td width="20%">
                    <label for="quotation-id">Sale #</label>
                  </td>
                  <td>
                    <div class="form-title">
                      {{ dbQuotation.barcode }}
                    </div>
                    <!-- <v-text-field
                      id="quotation-id"
                      dense
                      filled
                      readonly
                      hide-details
                      placeholder="Sell #"
                      solo
                      flat
                      :value="dbQuotation.barcode"
                      color="cyan"
                    /> -->
                  </td>
                </tr>
                <!-- <tr>
                  <td>
                    <label for="quotation-rate">Rate opportunity</label>
                  </td>
                  <td>
                    <v-rating
                      id="quotation-rate"
                      v-model.trim="dbQuotation.rating"
                      background-color="orange lighten-3"
                      color="orange"
                    ></v-rating>
                  </td>
                </tr> -->

                <tr>
                  <td>
                    <label for="customer-ref">Sale Invoice No.</label>
                  </td>
                  <td>
                    <v-text-field
                      id="customer-ref"
                      dense
                      filled
                      v-model="dbQuotation.sale_invoice_no"
                      hide-details
                      placeholder="Sale Invoice No."
                      solo
                      flat
                      color="cyan"
                      :maxlength="250"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="your-ref">Sales Agreement</label>
                  </td>
                  <td>
                    <v-text-field
                      id="your-ref"
                      dense
                      filled
                      v-model="dbQuotation.sale_agreement"
                      hide-details
                      placeholder="Sales Agreement"
                      solo
                      flat
                      color="cyan"
                      :maxlength="250"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="quotation-date">Date</label>
                  </td>
                  <td>
                    <date-picker
                      key="quotation-date"
                      id="quotation-date"
                      :min-date="todayDate"
                      placeholder="Date"
                      v-model="dbQuotation.date"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="sale-by">Sale by</label>
                  </td>
                  <td>
                    <v-autocomplete
                      id="sale-by"
                      dense
                      v-model="dbQuotation.sale_by"
                      item-text="display_name"
                      item-color="cyan"
                      item-value="id"
                      filled
                      :items="userList"
                      hide-details
                      placeholder="Sale by"
                      solo
                      flat
                      color="cyan"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="quote-by">Prepared by</label>
                  </td>
                  <td>
                    <span class="ml-1">{{
                      getPreparedByName(dbQuotation.quote_by)
                    }}</span>
                    <!-- <v-autocomplete
                      
                      id="quote-by"
                      dense
                      readonly
                      v-model="dbQuotation.quote_by"
                      item-text="display_name"
                      item-color="cyan"
                      item-value="id"
                      :items="userList"
                      filled
                      hide-details
                      placeholder="Prepared by"
                      solo
                      flat
                      color="cyan"
                    /> -->
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col cols="12" ref="qtLineItem">
              <v-card flat class="custom-grey-border remove-border-radius">
                <v-card-title class="headline grey lighten-4">
                  <h3 class="font-weight-700 custom-headline color-custom-blue">
                    Line Items
                  </h3>
                </v-card-title>
                <v-card-text class="p-6 font-size-16">
                  <line-item module-type="sell" />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" ref="qtNote">
              <v-card flat class="custom-grey-border remove-border-radius">
                <v-card-title class="headline grey lighten-4">
                  <h3 class="font-weight-700 custom-headline color-custom-blue">
                    Term Conditions & ATTACHMENTS
                  </h3>
                </v-card-title>
                <v-card-text class="p-6 font-size-16">
                  <v-layout>
                    <v-flex class="mr-4" md12>
                      <label class="font-size-16 font-weight-600"
                        >Term Conditions</label
                      >
                      <v-autocomplete
                        dense
                        color="cyan"
                        filled
                        label="Term Conditions"
                        solo
                        flat
                        class="my-2"
                        item-color="cyan"
                        item-text="label"
                        item-value="id"
                        hide-details
                        :disabled="formLoading"
                        :loading="formLoading"
                        :items="adminNoteList"
                        v-model="dbQuotation.admin_note_id"
                        v-on:change="update_admin_note()"
                      >
                      </v-autocomplete>
                      <tiny-mce
                        :disabled="formLoading"
                        v-model="dbQuotation.admin_note"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex md12 class="mt-4">
                      <label class="font-size-16 font-weight-600"
                        >Remark
                        <!-- <span class="text--secondary font-weight-500"
                          >(visible on PDF)</span
                        > -->
                      </label>
                      <!-- <v-autocomplete
                        dense
                        color="cyan"
                        filled
                        label="Remark"
                        solo
                        flat
                        class="my-2"
                        item-color="cyan"
                        item-text="label"
                        item-value="id"
                        hide-details
                        :disabled="formLoading"
                        :loading="formLoading"
                        :items="clientNoteList"
                        v-model="dbQuotation.client_note_id"
                        v-on:change="update_client_note()"
                      >
                      </v-autocomplete> -->
                      <tiny-mce
                        :disabled="formLoading"
                        v-model="dbQuotation.remark"
                      />
                    </v-flex>
                  </v-layout>

                  <v-layout class="mt-4">
                    <v-flex md6 class="mr-4">
                      <label class="font-size-16 font-weight-600"
                        >Attachments</label
                      >
                      <div class="ml-1">
                        Allowed Extensions:
                        doc,docx,txt,text,rtf,xsl,jpeg,png,gif,zip,rar,pdf,xlsx,word,jpg
                        <br />
                        Max files: 5, Max Size: 5MB
                      </div>
                      <table width="100%">
                        <tbody>
                          <tr
                            v-for="(row, index) in dbQuotation.attachments"
                            :key="`prev-attach-${index}`"
                          >
                            <td width="50%" class="pr-2 py-2">
                              <v-text-field
                                prepend-inner-icon="mdi-attachment mdi-rotate-90"
                                placeholder="File Name"
                                label="File Name"
                                dense
                                solo
                                flat
                                readonly
                                color="cyan"
                                v-model="row.name"
                                hide-details
                              ></v-text-field>
                            </td>
                            <td width="50%" class="pl-2 py-2">
                              <v-text-field
                                placeholder="File Name"
                                label="File Name"
                                dense
                                solo
                                flat
                                color="cyan"
                                v-model="row.name"
                                hide-details
                                class="has-delete-outer-icon"
                                append-outer-icon="mdi-delete"
                                v-on:click:append-outer="
                                  remove_row('dbQuotation.attachments', index)
                                "
                                :suffix="`.${row.ext}`"
                              ></v-text-field>
                            </td>
                          </tr>
                          <tr v-for="(row, index) in attachments" :key="index">
                            <td width="50%" class="pr-2 py-2">
                              <v-file-input
                                prepend-inner-icon="mdi-attachment mdi-rotate-90"
                                placeholder="Click here to select file"
                                label="Click here to select file"
                                prepend-icon=""
                                color="cyan"
                                solo
                                flat
                                v-model="row.accepted_file"
                                dense
                                v-on:change="update_file_name(index)"
                                hide-details
                              ></v-file-input>
                            </td>
                            <td width="50%" class="pl-2 py-2">
                              <v-text-field
                                placeholder="File Name"
                                label="File Name"
                                dense
                                solo
                                flat
                                color="cyan"
                                v-model="row.file_name"
                                hide-details
                                class="has-delete-outer-icon"
                                append-outer-icon="mdi-delete"
                                v-on:click:append-outer="
                                  remove_row('attachments', index)
                                "
                                :suffix="get_file_extension(row.file_type)"
                              ></v-text-field>
                            </td>
                          </tr>
                          <tr>
                            <td height="10px" colspan="2"></td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <v-btn
                                color="cyan white--text"
                                small
                                v-on:click="add_attachment_row()"
                                class="custom-bold-button"
                                v-if="attachments.length < 5"
                                >Add More...</v-btn
                              >
                            </td>
                          </tr>
                          <tr>
                            <td height="10px" colspan="2"></td>
                          </tr>
                        </tbody>
                      </table>
                    </v-flex>
                    <v-flex md6 class="ml-4"> </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
        <customer-select
          :dialog="customerDialog"
          v-on:close="customerDialog = false"
        />
        <person-select
          :dialog="contactDialog"
          v-on:close="contactDialog = false"
          :type="contactType"
        />
        <address-select
          :dialog="propertyDialog"
          v-on:close="propertyDialog = false"
          :type="propertyType"
          :label="propertyLabel"
        />
      </div>
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DatePicker from "@/view/components/QDatePicker.vue";
import LineItemV2 from "@/view/pages/partials/Line-Item-V3.vue";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import moment from "moment-timezone";
import ObjectPath from "object-path";

import {
  RESET_STATE,
  SET_CUSTOMER,
  SET_PROPERTY,
  SET_PROPERTY_CONTACT,
  SET_BILLING,
  SET_BILLING_CONTACT,
} from "@/core/services/store/visit.module";
import { UPLOAD, QUERY, POST, PUT } from "@/core/services/store/request.module";
import {
  SET_UOM,
  SET_GST,
  SET_EDIT_LINE_ITEM,
  SET_EDIT_CALCULATION,
  CLEAR_LINE_ITEM,
} from "@/core/services/store/line.item.module";
import CustomerSelect from "@/view/components/CustomerSelect.vue";
import PersonSelect from "@/view/components/PersonSelect.vue";
import AddressSelect from "@/view/components/AddressSelect.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import KTCookie from "@/assets/js/components/cookie";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "create-quotation",
  mixins: [ValidationMixin],
  data() {
    return {
      routePreventDialog: false,
      isCustomerCreated: false,
      customerList: [],

      formLoading: false,
      formValid: true,
      dbQuotation: {
        customer: 0,
        barcode: null,
        title: null,
        description: null,
        billing_contact_person: false,
        property_contact_person: false,
        rating: 1,
        date: null,
        sale_by: null,
        customer_ref: null,
        your_ref: null,
        quote_by: null,
        admin_note_id: 0,
        admin_note: null,
        client_note_id: 0,
        client_note: null,
        attachments: [],
      },
      attachments: [
        {
          accepted_file: null,
          file_name: null,
          file_type: null,
        },
      ],
      adminNoteList: [],
      clientNoteList: [],
      userList: [],
      todayDate: null,
      customerDialog: false,
      propertyType: null,
      propertyLabel: null,
      propertyDialog: false,
      contactType: null,
      contactDialog: false,
    };
  },
  components: {
    "customer-select": CustomerSelect,
    "person-select": PersonSelect,
    "address-select": AddressSelect,
    "line-item": LineItemV2,
    "date-picker": DatePicker,
    "tiny-mce": TinyMCE,
    CreateUpdateTemplate,
  },
  methods: {
    getPreparedByName(id = 0) {
      let name = null;
      if (this.userList.length) {
        name = this.userList.find((row) => (row.id = id));
        name = name.display_name;
      }
      return name;
    },
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.dbQuotation.description;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.dbQuotation.description = trimValue;
        e.preventDefault();
      }
    },

    manageLimitdescr(e) {
      if (
        this.dbQuotation.description &&
        this.dbQuotation.description.length > 199
      ) {
        e.preventDefault();
      }
    },

    selectCustomer() {
      const row = this.customerList.find(
        (row) => (row.id = this.dbQuotation.customer)
      );
      if (row) {
        //console.log(row);
        if (this.loading) {
          return false;
        }
        this.loading = true;
        this.$store
          .dispatch(SET_CUSTOMER, row)
          .then(() => {
            //this.$emit("close", true);
            // this.search = null;
            // this.list = [];
          })
          .catch((error) => {
            console.log({ error });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    remove_row(target, index) {
      ObjectPath.del(this, `${target}.${index}`);
      this.$nextTick(() => {
        if (!this.attachments.length) {
          this.add_attachment_row();
        }
      });
    },
    add_attachment_row() {
      this.attachments.push({
        accepted_file: null,
        file_name: null,
        file_type: null,
      });
    },
    get_file_extension(file_type) {
      if (file_type) {
        return `.${file_type}`;
      }
      return null;
    },
    update_file_name(index) {
      const file = this.attachments[index].accepted_file;
      if (file) {
        this.attachments[index].file_name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.attachments[index].file_type = file.name.split(".").pop();
        this.add_attachment_row();
      }
    },
    update_admin_note() {
      let output = this.lodash.find(this.adminNoteList, {
        id: this.dbQuotation.admin_note_id,
      });
      if (output) {
        this.dbQuotation.admin_note = output.description;
      }
    },
    update_client_note() {
      let output = this.lodash.find(this.clientNoteList, {
        id: this.dbQuotation.client_note_id,
      });
      if (output) {
        this.dbQuotation.client_note = output.description;
      }
    },
    uploadFiles() {
      let _this = this;
      return new Promise((resolve, reject) => {
        let formData = new FormData();

        let status = false;

        for (let i = 0; i < _this.attachments.length; i++) {
          if (_this.attachments[i].accepted_file) {
            status = true;
            formData.append(
              `files[${i}]`,
              _this.attachments[i].accepted_file,
              _this.attachments[i].file_name
            );
          }
        }

        if (!status) {
          resolve([]);
        } else {
          _this.$store
            .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    openDialog(type) {
      this.customerDialog = false;
      this.propertyType = null;
      this.propertyLabel = null;
      this.propertyDialog = false;
      this.contactType = null;
      this.contactDialog = false;

      if (type == "customer") {
        this.customerDialog = true;
      } else {
        if (!this.vCustomerId) {
          ErrorEventBus.$emit("update:error", "Please Select Customer.");
          return false;
        }
        if (type == "billing") {
          this.propertyType = "billing";
          this.propertyLabel = "Billing Location";
          this.propertyDialog = true;
        }
        if (type == "billing-contact") {
          this.contactType = "billing";
          this.contactDialog = true;
        }
        if (type == "property") {
          this.propertyType = "property";
          this.propertyLabel = "Service Location";
          this.propertyDialog = true;
        }
        if (type == "property-contact") {
          this.contactType = "property";
          this.contactDialog = true;
        }
      }
    },
    validateJob() {
      if (!this.vCustomerId) {
        ErrorEventBus.$emit("update:error", "Select Customer");
        return false;
      }

      if (!this.vPropertyId) {
        ErrorEventBus.$emit("update:error", "Select Service Location");
        return false;
      }

      if (!this.vPropertyContactId) {
        ErrorEventBus.$emit("update:error", "Select Service Contact Person");
        return false;
      }

      if (!this.vBillingId) {
        ErrorEventBus.$emit("update:error", "Select Billing Location");
        return false;
      }

      if (!this.vBillingContactId) {
        ErrorEventBus.$emit("update:error", "Select Billing Contact Person");
        return false;
      }

      if (!this.lSelectedLineItem || !this.lSelectedLineItem.length) {
        ErrorEventBus.$emit("update:error", "Select Line Item");
        return false;
      }

      const validateStatus = this.$refs.createQuotationForm.validate();

      const formErrors = this.validateForm(this.$refs.createQuotationForm);

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (validateStatus) {
        this.onSubmit();
      }
    },
    async onSubmit() {
      const new_attachments = await this.uploadFiles();

      const request = {
        ...this.dbQuotation,
        attachments: [...this.dbQuotation.attachments, ...new_attachments],
        customer: this.vCustomerId,
        property: this.vPropertyId,
        billing: this.vBillingId,
        property_contact_person: this.vPropertyContactId,
        billing_contact_person: this.vBillingContactId,
        "line-item": this.lSelectedLineItem,
        calculation: this.lCalculation,
      };

      let requestType = POST;
      let requestURL = "sell";

      let quotationId = this.$route?.query?.edit ?? 0;

      if (quotationId) {
        requestType = PUT;
        requestURL = `sell/${quotationId}`;
      }

      this.formLoading = true;

      this.$store
        .dispatch(requestType, { url: requestURL, data: request })
        .then(({ data }) => {
          this.$store.dispatch(RESET_STATE);
          this.$store.dispatch(CLEAR_LINE_ITEM);
          this.isCustomerCreated = true;
          this.$nextTick(() => {
            this.$router.push(
              this.getDefaultRoute("sell.detail", {
                params: { id: data?.id },
              })
            );
          });
        })
        .catch(() => {
          this.formLoading = false;
        });
    },

    get_attributes() {
      this.$store
        .dispatch(QUERY, {
          url: "sell/options",
          data: { id: ObjectPath.get(this.$route, "query.edit") },
        })
        .then((response) => {
          this.dbQuotation.barcode = ObjectPath.get(response, "data.barcode");
          this.customerList = ObjectPath.get(response, "data.customers");
          //  console.log(this.customerList );
          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Sale", route: "sell" },
            { title: "Create" },
            { title: this.dbQuotation.barcode },
          ]);

          this.$store.dispatch(
            SET_UOM,
            ObjectPath.get(response, "data.uom_list", [])
          );

          this.$store.dispatch(
            SET_GST,
            ObjectPath.get(response, "data.entity.gstvalue", 0)
          );
          this.adminNoteList = ObjectPath.get(
            response,
            "data.admin_notes_list",
            []
          );
          this.clientNoteList = ObjectPath.get(
            response,
            "data.client_notes_list",
            []
          );

          // let defaultClientObject = this.lodash.find(this.clientNoteList, {
          //   primary: 1,
          // });
          // if (defaultClientObject) {
          //   this.dbQuotation.client_note_id = defaultClientObject.id;
          //   this.dbQuotation.client_note = defaultClientObject.description;
          // }

          let defaultAdminObject = this.lodash.find(this.adminNoteList, {
            primary: 1,
          });
          if (defaultAdminObject) {
            this.dbQuotation.admin_note_id = defaultAdminObject.id;
            this.dbQuotation.admin_note = defaultAdminObject.description;
          }

          this.userList = ObjectPath.get(response, "data.sales_users", []);

          this.dbQuotation.sale_by = ObjectPath.get(
            response,
            "data.user_id",
            0
          );

          this.dbQuotation.quote_by = ObjectPath.get(
            response,
            "data.user_id",
            0
          );

          let dbQuotation = ObjectPath.get(response, "data.detail");

          if (dbQuotation?.id) {
            this.dbQuotation.barcode = dbQuotation?.barcode;
            //this.dbQuotation.customer = dbQuotation?.customer;
            this.dbQuotation.title = dbQuotation?.title;
            this.dbQuotation.description = dbQuotation?.description;
            this.dbQuotation.customer_ref = dbQuotation?.customer_ref;
            this.dbQuotation.your_ref = dbQuotation?.your_ref;
            this.dbQuotation.quote_by = dbQuotation?.quote_by;
            this.dbQuotation.sale_by = dbQuotation?.sale_by;
            this.dbQuotation.sale_invoice_no = dbQuotation?.sales_invoice_no;
            this.dbQuotation.sale_agreement = dbQuotation?.sales_agreement;
            this.dbQuotation.remark = dbQuotation?.remark;

            this.dbQuotation.date = moment(dbQuotation?.date).format(
              "YYYY-MM-DD"
            );
            this.dbQuotation.rating = dbQuotation?.rating;
            this.dbQuotation.attachments = dbQuotation?.attachments ?? [];

            this.dbQuotation.notify_billing_contact_person =
              dbQuotation?.notify_billing_contact_person;
            this.dbQuotation.notify_property_contact_person =
              dbQuotation?.notify_property_contact_person;

            this.dbQuotation.client_note_id = dbQuotation?.client_note_id;
            this.dbQuotation.client_note = dbQuotation?.client_note;

            this.dbQuotation.admin_note_id = dbQuotation?.admin_note_id;
            this.dbQuotation.admin_note = dbQuotation?.admin_note;
            console.log(dbQuotation?.customer_relation);
            this.$store.commit(SET_CUSTOMER, dbQuotation?.customer_relation);
            this.$store.commit(SET_PROPERTY, dbQuotation?.property_relation);
            this.$store.commit(
              SET_PROPERTY_CONTACT,
              dbQuotation?.property_contact_person_relation
            );
            this.$store.commit(SET_BILLING, dbQuotation?.billing_relation);
            this.$store.commit(
              SET_BILLING_CONTACT,
              dbQuotation?.billing_contact_person_relation
            );

            this.$store.dispatch(SET_EDIT_CALCULATION, {
              ctx_discount_label: dbQuotation?.ctx_discount_label ?? null,
              ctx_discount_value: dbQuotation?.ctx_discount_value ?? 0,
              ctx_discount_type: dbQuotation?.ctx_discount_type ?? 1,
              discount_type: dbQuotation?.discount_type ?? 1,
              discount_value: dbQuotation?.discount_value ?? 0,
              tax_active: dbQuotation?.tax_active ?? false,
              adjustment: dbQuotation?.adjustment ?? 0,
              show_price: true,
              discount_level: dbQuotation?.discount_level ?? "transaction",
            });

            this.$store.dispatch(
              SET_EDIT_LINE_ITEM,
              dbQuotation?.line_item ?? []
            );

            this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Sale", route: "sell" },
              { title: "Update" },
              { title: this.dbQuotation.barcode },
            ]);
          }
        })
        .catch((error) => {
          this.logError(error);
          this.goBack();
        })
        .finally(() => {
          // this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.get_attributes();

    this.$store.dispatch(SET_BREADCRUMB, []);
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.isCustomerCreated) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
  destroyed() {
    this.$store.dispatch(RESET_STATE);
    this.$store.dispatch(CLEAR_LINE_ITEM);
  },
  beforeDestroy() {
    KTCookie.setCookie("kt_aside_toggle_state", "off");
    localStorage.setItem("kt_aside_toggle_state", "off");
  },
  created() {
    this.todayDate = moment().format("YYYY-MM-DD");
    this.dbQuotation.date = this.todayDate;
  },
  beforeCreate() {
    KTCookie.setCookie("kt_aside_toggle_state", "on");
    localStorage.setItem("kt_aside_toggle_state", "on");
    this.$store.dispatch(RESET_STATE);
    this.$store.dispatch(CLEAR_LINE_ITEM);
  },
  computed: {
    ...mapGetters([
      "vCustomer",
      "vProperty",
      "vPropertyContact",
      "vBilling",
      "vBillingContact",
      "vCustomerId",
      "vPropertyId",
      "vPropertyContactId",
      "vBillingId",
      "vBillingContactId",
      "lSelectedLineItem",
      "lCalculation",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.main-create-page .form-title {
  font-size: 24px;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-weight: 500;
}
.quotation-create-page {
  border-top: solid 8px #f7941e;
  label {
    padding-left: 5px;
    font-weight: 600;
  }
  p {
    padding-left: 5px;
  }
}
.form-action {
  display: flex;
  justify-content: space-between;

  .form-title {
    font-size: 24px;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    font-weight: 700;
    color: #24326d;
    letter-spacing: 1.5px;
    line-height: 1.11em;
    .form-title-link {
      color: #4d6974;
      border-bottom: dashed 2px #4d6974;
    }
  }
}
</style>
