<!--This Component is only for sell and rental-->
<template>
  <div class="line-item-v2">
    <div class="d-flex mb-2 align-center justify-space-between">
      <div>
        <v-btn-toggle color="cyan" group>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="equipment"
            v-on:click="open_dialog('top', 'equipment')"
          >
            Equipment
          </v-btn>
          <!-- <v-btn
            color="cyan"
            class="custom-bold-button"
            value="door"
            v-on:click="open_dialog('top', 'door')"
          >
            Door
          </v-btn> -->
          <!-- <v-btn
            color="cyan"
            class="custom-bold-button"
            value="part"
            v-on:click="open_dialog('top', 'part')"
          >
            Part
          </v-btn> -->
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="product"
            v-on:click="open_dialog('top', 'product')"
            v-if="moduleType != 'rental'"
          >
            Spare part
          </v-btn>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="header"
            v-on:click="add_header('top')"
          >
            Header
          </v-btn>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="other"
            v-on:click="add_other('top')"
          >
            Other
          </v-btn>
        </v-btn-toggle>
      </div>
      <div class="d-flex align-center">
        <template v-if="false">
          <v-checkbox
            class="my-0 py-0"
            v-model="show_image"
            color="cyan white--text"
            label="Show Image"
          />
          <div class="pl-4 ml-4 custom-border-left">
            <v-checkbox
              class="my-0 py-0"
              v-model="show_price"
              color="cyan white--text"
              label="Show Price"
            />
          </div>
          <div
            v-if="show_price"
            class="d-flex align-center ml-4 pl-4 custom-border-left"
          >
            <div class="mr-2">
              <label for="discount-level" class="mb-0 font-weight-600"
                >Discount Level</label
              >
            </div>
            <div>
              <v-radio-group
                v-on:change="calculate_total()"
                id="discount-level"
                v-model="discount_level"
                row
                hide-details
                class="px-0 py-0"
              >
                <v-radio
                  label="Line Item"
                  value="line_item"
                  color="cyan"
                ></v-radio>
                <v-radio
                  label="Transaction"
                  value="transaction"
                  color="cyan"
                ></v-radio>
              </v-radio-group>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="custom-grey-border">
      <div class="d-flex grey lighten-4">
        <div
          class="color-custom-blue py-2 font-weight-700 text-center"
          :style="`min-width: ${vWidth.sno}px; max-width: ${vWidth.sno}px; width: ${vWidth.sno}px`"
        >
          #
        </div>
        <div
          class="color-custom-blue py-2 font-weight-700 text-left"
          style="width: 100%"
        >
          Line Item
        </div>
        <div
          v-if="show_price"
          class="color-custom-blue py-2 font-weight-700 text-center"
          :style="`min-width: ${vWidth.unit}px; max-width: ${vWidth.unit}px; width: ${vWidth.unit}px`"
        >
          Unit
        </div>
        <div
          v-if="show_price && false"
          class="color-custom-blue py-2 font-weight-700 text-center"
          :style="`min-width: ${vWidth.quantity}px; max-width: ${vWidth.quantity}px; width: ${vWidth.quantity}px`"
        >
          Quantity
        </div>
        <div
          class="color-custom-blue py-2 font-weight-700 text-center"
          :style="`min-width: ${vWidth.rate}px; max-width: ${vWidth.rate}px; width: ${vWidth.rate}px`"
        >
          Unit Cost
        </div>
        <div
          v-if="show_price && discount_level == 'line_item'"
          class="color-custom-blue py-2 font-weight-700 text-center"
          :style="`min-width: ${vWidth.discount}px; max-width: ${vWidth.discount}px; width: ${vWidth.discount}px`"
        >
          Discount
        </div>
        <div
          v-if="show_price"
          class="color-custom-blue py-2 font-weight-700 text-center"
          :style="`min-width: ${vWidth.total}px; max-width: ${vWidth.total}px; width: ${vWidth.total}px`"
        >
          Total
        </div>
        <div
          class="color-custom-blue py-2 font-weight-700 text-center"
          :style="`min-width: ${vWidth.action}px; max-width: ${vWidth.action}px; width: ${vWidth.action}px`"
        ></div>
      </div>

      <Draggable
        tag="ul"
        v-model="selected_line_items"
        class="line-item-drag-group"
        draggable=".line-item-drag"
        handle=".line-item-drag-icon"
        ghost-class="line-item-ghost"
        animation="200"
        group="line-item-drag"
        v-on:start="drag = true"
        v-on:end="drag = false"
        v-on:change="
          change_drag();
          emit_values();
        "
      >
        <transition-group
          tag="li"
          type="transition"
          :name="!drag ? 'flip-list' : null"
          v-for="(row, index) in selected_line_items"
          class="line-item-drag"
          :key="`line-item-drag-${index}`"
        >
          <div
            :key="`line-item-drag-children-${index}`"
            :id="`line-item-drag-${index}`"
            class="d-flex my-2"
            style="position: relative"
            :class="{
              'item-is-child': row.is_child,
              'item-is-second-child': row.is_second_child,
            }"
          >
            <div
              class="font-weight-600 text-center"
              :style="`min-width: ${vWidth.sno}px; max-width: ${vWidth.sno}px; width: ${vWidth.sno}px`"
            >
              <v-icon
                v-if="!row.is_child && !row.is_second_child"
                class="line-item-drag-icon cursor-move"
                color="color-custom-blue"
                >mdi-drag</v-icon
              >
            </div>

            <template v-if="row.is_child || row.is_second_child">
              <div
                class="font-weight-600 text-center"
                :style="`min-width: ${vWidth.sno}px; max-width: ${vWidth.sno}px; width: ${vWidth.sno}px`"
              >
                <v-icon
                  v-if="row.is_child"
                  class="line-item-drag-icon cursor-move"
                  color="color-custom-blue"
                  >mdi-drag</v-icon
                >
              </div>
              <div
                v-if="row.is_second_child"
                class="font-weight-600 text-center"
                :style="`min-width: ${vWidth.sno}px; max-width: ${vWidth.sno}px; width: ${vWidth.sno}px`"
              >
                <v-icon
                  class="line-item-drag-icon cursor-move"
                  color="color-custom-blue"
                  >mdi-drag</v-icon
                >
              </div>
            </template>

            <div class="text-left" style="width: 100%">
              <div class="d-flex">
                <div>
                  <v-avatar
                    v-if="
                      row.product_type == 'equipment' ||
                      row.product_type == 'goods'
                    "
                    size="40"
                  >
                    <img
                      v-if="row?.image?.url"
                      :src="row?.image?.url"
                      :alt="row?.image?.name"
                    />

                    <img v-else :src="$defaultImage" />
                  </v-avatar>

                  <v-chip
                    color="blue"
                    label
                    class="white--text"
                    small
                    v-if="row.serial_no"
                  >
                    <span class="font-size-16 font-weight-500">
                      {{ row.serial_no ? row.serial_no : "" }}
                    </span>
                  </v-chip>
                </div>
                <div style="width: 100%" :class="{ 'ml-4': show_image }">
                  <v-text-field
                    v-model="row.product"
                    class="line-item-header-text px-0"
                    :placeholder="lodash.capitalize(row.product_type)"
                    dense
                    :maxlength="250"
                    filled
                    solo
                    flat
                    color="cyan"
                  >
                    <template v-slot:append>
                      <v-chip
                        color="cyan white--text"
                        label
                        class="lip-type text-uppercase font-weight-600"
                        :class="{
                          product: row.product_type == 'door',
                          service: row.product_type == 'service',
                          equipment: row.product_type == 'parts',
                          other: row.product_type == 'other',
                          'btx-header': row.product_type == 'header',
                        }"
                      >
                        {{ row.type == "product" ? "Spare part" : row.type }}
                      </v-chip>
                    </template>
                  </v-text-field>
                  <v-textarea
                    class="px-0"
                    v-model="row.description"
                    placeholder="Description"
                    dense
                    filled
                    solo
                    flat
                    rows="2"
                    auto-grow
                    color="cyan"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="row.product_type != 'header' && row.product_type != 'other'"
              class="text-center"
              :style="`min-width: ${vWidth.unit}px; max-width: ${vWidth.unit}px; width: ${vWidth.unit}px`"
            >
              <v-select
                :items="lUOM"
                v-model="row.unit"
                hide-details
                item-color="cyan"
                class="line-item-header-text text-h6 px-0"
                placeholder="Unit"
                dense
                filled
                solo
                flat
                v-on:change="emit_values()"
                color="cyan"
              />
              <div class="ml-2 d-flex flex-column align-start">
                <v-checkbox
                  dense
                  v-if="row.has_child && false"
                  class="my-0 py-0 px-0 mx-0 child-row-checkbox position-absolute"
                  color="cyan"
                  label="Update total from child rows"
                  v-model="row.sum_rows"
                  v-on:change="
                    calculate_total();
                    update_total_child_sum($event, index);
                  "
                />

                <div
                  :style="`margin-top: ${row.has_child ? '24px' : '0px'}`"
                  v-if="false"
                >
                  <v-checkbox
                    dense
                    class="my-0 py-0 px-0 mx-0 child-row-checkbox"
                    color="cyan"
                    label="All"
                    :key="`show-all-key-${index}`"
                    :indeterminate="row.show_indeterminate"
                    v-on:change="show_all($event, index)"
                    v-model="row.show_all"
                  />
                  <v-checkbox
                    class="my-0 py-0 px-0 mx-0 child-row-checkbox"
                    color="cyan"
                    label="Unit"
                    dense
                    :key="`show-unit-key-${index}`"
                    v-on:change="show_indeterminate($event, index, 'unit')"
                    v-model="row.show_unit"
                  />
                </div>
              </div>
            </div>

            <div
              v-if="show_price && false"
              class="text-center"
              :style="`min-width: ${vWidth.quantity}px; max-width: ${vWidth.quantity}px; width: ${vWidth.quantity}px`"
            >
              <!--row.product_type=='equipment' on disable-->
              <v-text-field
                :disabled="true"
                class="line-item-header-text px-0"
                placeholder="Quantity"
                :maxlength="6"
                dense
                filled
                solo
                flat
                type="number"
                v-model="row.quantity"
                :prepend="row.uom"
                v-on:keyup="calculate_total()"
                v-on:keypress="isNumber($event)"
                color="cyan"
              />
              <div class="ml-2 d-flex flex-column align-start">
                <div :style="`margin-top: ${row.has_child ? '48px' : '24px'}`">
                  <v-checkbox
                    v-if="false"
                    dense
                    class="my-0 py-0 px-0 mx-0 child-row-checkbox"
                    color="cyan"
                    label="Quantity"
                    :key="`show-quantity-key-${index}`"
                    v-on:change="show_indeterminate($event, index, 'quantity')"
                    v-model="row.show_quantity"
                  />
                </div>
              </div>
            </div>

            <div
              class="text-center"
              :style="`min-width: ${vWidth.rate}px; max-width: ${vWidth.rate}px; width: ${vWidth.rate}px`"
              v-if="row.product_type != 'header' && row.product_type != 'other'"
            >
              <v-text-field
                class="line-item-header-text px-0"
                placeholder="Unit Cost"
                :maxlength="6"
                dense
                filled
                solo
                flat
                :disabled="row.custom_val ? true : false"
                type="number"
                v-model="row.rate"
                v-on:keyup="calculate_total()"
                v-on:keypress="
                  limitDecimal($event, row.rate);
                  row.custom_val = null;
                "
                color="cyan"
              />

              <div class="d-flex flex-column align-start" v-if="false">
                <v-text-field
                  :disabled="row.rate ? true : false"
                  class="line-item-header-text px-0 py-0 line-item-custom-value"
                  placeholder="Custom Value"
                  dense
                  :style="`min-width: ${
                    vWidth.rate + vWidth.total
                  }px; max-width: ${vWidth.rate + vWidth.total}px; width: ${
                    vWidth.rate + vWidth.total
                  }px`"
                  filled
                  solo
                  flat
                  v-model="row.custom_val"
                  v-on:keypress="row.rate = null"
                  v-on:change="emit_values()"
                  color="cyan"
                />
                <div class="ml-2">
                  <v-checkbox
                    dense
                    class="my-0 py-0 px-0 mx-0 child-row-checkbox"
                    color="cyan"
                    label="Unit Cost"
                    :key="`show-unit-cost-key-${index}`"
                    v-on:change="
                      show_indeterminate($event, index, 'rate');
                      emit_values();
                    "
                    v-model="row.show_rate"
                  />
                </div>
              </div>
            </div>

            <div
              v-if="show_price && discount_level == 'line_item'"
              class="text-center"
              :style="`min-width: ${vWidth.discount}px; max-width: ${vWidth.discount}px; width: ${vWidth.discount}px`"
            >
              <div class="d-flex">
                <v-text-field
                  hide-details
                  class="line-item-header-text px-0"
                  placeholder="Discount"
                  :maxlength="6"
                  dense
                  filled
                  solo
                  flat
                  type="number"
                  v-model="row.discount_value"
                  v-on:keyup="calculate_total()"
                  v-on:keypress="limitDecimal($event, row.discount_value)"
                  color="cyan"
                  style="width: 50%; min-width: unset"
                />
                <v-select
                  :items="discount_type_list"
                  v-model="row.discount_type"
                  hide-details
                  item-text="type"
                  item-value="value"
                  item-color="cyan"
                  class="line-item-header-text discount-type text-h6 px-0"
                  placeholder="Type"
                  dense
                  filled
                  solo
                  flat
                  v-on:change="calculate_total()"
                  color="cyan"
                  style="width: 50%"
                />
              </div>
              <div class="ml-2 d-flex flex-column align-start">
                <div :style="`margin-top: ${row.has_child ? '48px' : '24px'}`">
                  <v-checkbox
                    dense
                    class="my-0 py-0 px-0 mx-0 child-row-checkbox"
                    color="cyan"
                    label="Discount"
                    :key="`show-discount-key-${index}`"
                    v-on:change="
                      show_indeterminate($event, index, 'discount');
                      emit_values();
                    "
                    v-model="row.show_discount"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="row.product_type != 'header' && row.product_type != 'other'"
              class="text-center"
              :style="`min-width: ${vWidth.total}px; max-width: ${vWidth.total}px; width: ${vWidth.total}px`"
            >
              <v-text-field
                class="line-item-header-text px-0"
                placeholder="Total"
                dense
                filled
                solo
                readonly
                :maxlength="6"
                flat
                type="number"
                v-model="row.total"
                v-on:keyup="calculate_total()"
                v-on:keypress="limitDecimal($event, row.total)"
                color="cyan"
              />
              <div class="ml-2 d-flex flex-column align-start" v-if="false">
                <div :style="`margin-top: ${row.has_child ? '60px' : '36px'}`">
                  <v-checkbox
                    dense
                    class="my-0 py-0 px-0 mx-0 child-row-checkbox"
                    color="cyan"
                    label="Total"
                    :key="`show-total-key-${index}`"
                    v-model="row.show_total"
                    v-on:change="show_indeterminate($event, index, 'total')"
                  />
                </div>
              </div>
            </div>

            <div
              class="font-weight-600 text-right"
              :style="`min-width: ${vWidth.action}px; max-width: ${vWidth.action}px; width: ${vWidth.action}px`"
            >
              <!-- v-if="false"-->
              <v-menu v-if="!row.is_second_child && false" bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon color="blue darken-4">
                    <v-icon>mdi-dots-horizontal-circle-outline</v-icon>
                  </v-btn>
                </template>
                <v-list class="py-0" min-width="150px">
                  <v-list-item
                    link
                    v-if="!row.is_child"
                    class="border-bottom"
                    v-on:click="add_child(index, 'header', false)"
                  >
                    <v-list-item-title class="font-weight-600">
                      Add Header</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item
                    link
                    class="border-bottom"
                    v-on:click="add_child(index, 'door', row.is_child)"
                  >
                    <v-list-item-title class="font-weight-600">
                      Add Door</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item
                    link
                    class="border-bottom"
                    v-on:click="add_child(index, 'part', row.is_child)"
                  >
                    <v-list-item-title class="font-weight-600">
                      Add Part</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item
                    link
                    class="border-bottom"
                    v-on:click="add_child(index, 'service', row.is_child)"
                  >
                    <v-list-item-title class="font-weight-600">
                      Add Service</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item
                    link
                    v-on:click="add_child(index, 'other', row.is_child)"
                  >
                    <v-list-item-title class="font-weight-600">
                      Add Other</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn v-on:click="remove_row(index)" icon color="red lighten-1">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </div>
        </transition-group>
      </Draggable>
      <template v-if="!selected_line_items.length">
        <div class="custom-border-top p-3">
          <p
            class="m-0 p-4 font-size-16 font-weight-500 light-gray-background custom-grey-border d-flex align-center"
          >
            <v-icon class="mr-1">mdi-alert-circle-outline</v-icon>
            Oops... Nothing Found.
          </p>
        </div>
      </template>
    </div>
    <div class="d-flex mt-2">
      <v-btn-toggle color="cyan" group>
        <v-btn
          color="cyan"
          class="custom-bold-button"
          value="equipment"
          v-on:click="open_dialog('bottom', 'equipment')"
        >
          Equipment
        </v-btn>
        <!-- <v-btn
          color="cyan"
          class="custom-bold-button"
          value="door"
          v-on:click="open_dialog('bottom', 'door')"
        >
          Door
        </v-btn> -->
        <!-- <v-btn
          color="cyan"
          class="custom-bold-button"
          value="part"
          v-on:click="open_dialog('bottom', 'part')"
        >
          Part
        </v-btn> -->
        <v-btn
          color="cyan"
          class="custom-bold-button"
          value="product"
          v-on:click="open_dialog('bottom', 'product')"
          v-if="moduleType != 'rental'"
        >
          Spare part
        </v-btn>
        <v-btn
          color="cyan"
          class="custom-bold-button"
          value="header"
          v-on:click="add_header('bottom')"
        >
          Header
        </v-btn>

        <v-btn
          color="cyan"
          class="custom-bold-button"
          value="other"
          v-on:click="add_other('bottom')"
        >
          Other
        </v-btn>
      </v-btn-toggle>
    </div>
    <div>
      <table width="100%" style="table-layout: fixed">
        <tbody>
          <tr style="font-size: 16px">
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              Sub Total
            </td>
            <td width="15%" valign="middle" align="center"></td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              {{ formatMoney(subtotal) }}
            </td>
          </tr>
          <template v-if="discount_level == 'transaction'">
            <tr>
              <td
                width="70%"
                valign="middle"
                align="right"
                class="font-weight-600"
              >
                Discount
              </td>
              <td width="15%" valign="middle" align="center">
                <div class="d-flex">
                  <v-text-field
                    hide-details
                    class="line-item-header-text px-0"
                    placeholder="Discount"
                    dense
                    :maxlength="6"
                    filled
                    solo
                    flat
                    v-model="discount_value"
                    v-on:keyup="calculate_total()"
                    v-on:keypress="limitDecimal($event, discount_value)"
                    color="cyan"
                  />
                  <v-select
                    :items="discount_type_list"
                    v-model="discount_type"
                    hide-details
                    item-text="type"
                    item-value="value"
                    item-color="cyan"
                    class="line-item-header-text discount-type text-h6 px-0"
                    placeholder="Type"
                    dense
                    filled
                    solo
                    flat
                    v-on:change="calculate_total()"
                    color="cyan"
                  />
                </div>
              </td>
              <td
                width="15%"
                valign="middle"
                align="right"
                class="font-weight-600"
              >
                {{ formatMoney(discount) }}
              </td>
            </tr>
            <tr>
              <td
                width="70%"
                valign="middle"
                align="right"
                class="font-weight-600"
              >
                <v-text-field
                  style="max-width: 250px"
                  hide-details
                  class="line-item-header-text px-0"
                  placeholder="Discount Label"
                  dense
                  :maxlength="50"
                  filled
                  solo
                  flat
                  v-model="ctx_discount_label"
                  v-on:keyup="calculate_total()"
                  color="cyan"
                />
              </td>
              <td width="15%" valign="middle" align="center">
                <div class="d-flex">
                  <v-text-field
                    hide-details
                    class="line-item-header-text px-0"
                    placeholder="Discount"
                    dense
                    :maxlength="6"
                    filled
                    solo
                    flat
                    v-model="ctx_discount_value"
                    v-on:keyup="calculate_total()"
                    v-on:keypress="limitDecimal($event, ctx_discount_value)"
                    color="cyan"
                  />
                  <v-select
                    :items="discount_type_list"
                    v-model="ctx_discount_type"
                    hide-details
                    item-text="type"
                    item-value="value"
                    item-color="cyan"
                    class="line-item-header-text discount-type text-h6 px-0"
                    placeholder="Type"
                    dense
                    filled
                    solo
                    flat
                    v-on:change="calculate_total()"
                    color="cyan"
                  />
                </div>
              </td>
              <td
                width="15%"
                valign="middle"
                align="right"
                class="font-weight-600"
              >
                {{ formatMoney(ctx_discount) }}
              </td>
            </tr>
          </template>
          <tr>
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              Tax [GST {{ lGST }}%]
            </td>
            <td width="15%" valign="middle" align="center">
              <v-checkbox
                v-on:change="calculate_total()"
                v-model="tax_active"
                class="my-0 py-0 px-0 mx-0"
                color="cyan"
                :label="`Tax Amount: ${formatMoney(gst_amount)}`"
              />
            </td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              {{ formatMoney(gst) }}
            </td>
          </tr>
          <tr>
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              Adjustment
            </td>
            <td width="15%" valign="middle" align="center">
              <v-text-field
                hide-details
                class="line-item-header-text px-0 line-item-adjustment"
                placeholder="Adjustment"
                dense
                filled
                :maxlength="6"
                solo
                flat
                v-model="adjustment"
                v-on:keyup="calculate_total()"
                v-on:keypress="limitDecimal($event, adjustment)"
                color="cyan"
              >
                <template v-slot:append>
                  <v-btn depressed v-on:click="auto_adjust()">Auto</v-btn>
                </template>
              </v-text-field>
            </td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              {{ formatMoney(adjustment) }}
            </td>
          </tr>
          <tr style="font-size: 18px">
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              Grand Total
            </td>
            <td width="15%" valign="middle" align="center"></td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              {{ formatMoney(total) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <LineItemDialog
      :key="`line-item-dialog-${dialog_key}`"
      :product-type="product_type"
      :product-dialog="product_dialog"
      v-on:update-values="update_line_item($event)"
      v-on:close="product_dialog = false"
      :module-type-str="moduleType"
      :rental-start-date="rentalStartDate"
      :rental-end-date="rentalEndDate"
      :duplicate-id="duplicateId"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Draggable from "vuedraggable";
import LineItemDialog from "@/view/components/LineItemDialog.vue";
import {
  SET_LINE_ITEM,
  SET_CALCULATION,
} from "@/core/services/store/line.item.module";
import { toBtxNumber, toBtxFixed } from "@/core/services/common.service";
import CommonMixin from "@/core/plugins/common-mixin.js";
import { v4 as uuidv4 } from "uuid";
import ObjectPath from "object-path";
import { ErrorEventBus } from "@/core/lib/message.lib";

export default {
  name: "line-item-v2",
  mixins: [CommonMixin],
  props: {
    moduleType: {
      type: String,
      default: null,
    },
    duplicateId: {
      type: Number,
      default: 0,
    },
    rentalStartDate: {
      type: String,
      default: null,
    },
    rentalEndDate: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      drag: false,
      dialog_key: Number(new Date()),
      vWidth: {
        sno: 30,
        unit: 100,
        quantity: 100,
        discount: 100,
        rate: 100,
        total: 100,
        action: 80,
      },
      product_type: null,
      product_location: null,
      product_dialog: false,
      child_index: null,
      append_child: false,
      tax_active: false,
      show_price: true,
      show_image: false,
      is_second_child: false,
      discount_level: "transaction",
      selected_line_items: [],
      subtotal: 0,
      discount: 0,
      gst: 0,
      gst_amount: 0,
      adjustment: 0,
      total: 0,
      ctx_discount_label: null,
      ctx_discount_value: null,
      ctx_discount_type: 1,
      ctx_discount: null,
      discount_value: null,
      discount_type: 1,
      discount_type_list: [
        { type: "%", value: 2 },
        { type: "$", value: 1 },
      ],
    };
  },
  methods: {
    show_indeterminate(status, index, type) {
      let {
        show_unit,
        show_quantity,
        show_rate,
        /*show_discount,*/ show_total,
      } = this.selected_line_items[index];

      if (
        show_unit &&
        show_quantity &&
        show_rate /*&& show_discount*/ &&
        show_total
      ) {
        //console.log("all true");
        this.selected_line_items[index].show_indeterminate = false;
        this.$nextTick(() => {
          this.selected_line_items[index].show_all = true;
        });
      } else if (
        !show_unit &&
        !show_quantity &&
        !show_rate /*&& !show_discount*/ &&
        !show_total
      ) {
        console.log("all false");
        this.selected_line_items[index].show_indeterminate = false;
        this.$nextTick(() => {
          this.selected_line_items[index].show_all = false;
        });
      } else {
        ///console.log("anyone true");
        this.selected_line_items[index].show_indeterminate = true;
        this.$nextTick(() => {
          this.selected_line_items[index].show_all = false;
        });
      }
      console.log({ status, index, type });
      //
    },
    show_all(status, index) {
      this.selected_line_items[index].show_all = status ? true : false;
      this.selected_line_items[index].show_unit = status ? true : false;
      this.selected_line_items[index].show_quantity = status ? true : false;
      this.selected_line_items[index].show_rate = status ? true : false;
      this.selected_line_items[index].show_discount = status ? true : false;
      this.selected_line_items[index].show_total = status ? true : false;
      this.selected_line_items[index].show_indeterminate = false;

      /*if (this.selected_line_items[index]?.is_child) {
        this.selected_line_items[index].show_all = status;
        this.selected_line_items[index].show_unit = status;
        this.selected_line_items[index].show_quantity = status;
        this.selected_line_items[index].show_rate = status;
        this.selected_line_items[index].show_discount = status;
        this.selected_line_items[index].show_total = status;
        return false;
      }
      this.selected_line_items[index].show_all = status;
      this.selected_line_items[index].show_unit = status;
      this.selected_line_items[index].show_quantity = status;
      this.selected_line_items[index].show_rate = status;
      this.selected_line_items[index].show_discount = status;
      this.selected_line_items[index].show_total = status;
      for (let i = index + 1; i < this.selected_line_items.length; i++) {
        if (!this.selected_line_items[i]?.is_child) {
          break;
        }
        this.selected_line_items[i].show_all = status;
        this.selected_line_items[i].show_unit = status;
        this.selected_line_items[i].show_quantity = status;
        this.selected_line_items[i].show_rate = status;
        this.selected_line_items[i].show_discount = status;
        this.selected_line_items[i].show_total = status;
      }*/
    },
    change_drag() {
      this.update_child_attribute();
    },
    update_child_attribute() {
      let round = 0;
      this.calculate_total();
      this.$nextTick(() => {
        for (let i = 0; i < this.selected_line_items.length; i++) {
          let p_index = i - 1;
          this.selected_line_items[i].has_child = false;
          let is_second_child = ObjectPath.has(
            this.selected_line_items[p_index],
            "is_second_child"
          );
          let has_second_child = ObjectPath.has(
            this.selected_line_items[p_index],
            "has_second_child"
          );
          if (!is_second_child && !has_second_child) {
            if (!this.selected_line_items[i]?.is_child) {
              round = 0;
            }
          }
          if (round == 1) {
            if (!is_second_child && !has_second_child) {
              this.selected_line_items[p_index].has_child = true;
              this.update_total_child_sum(
                this.selected_line_items[p_index].sum_rows,
                p_index
              );
            }
          }
          round++;
        }
      });
    },
    update_total_child_sum(status, index) {
      if (status) {
        let total_amount = 0;
        let status = true;
        let t_index = index;
        while (status) {
          t_index++;
          let row = this.selected_line_items[t_index];
          if (!row) {
            status = false;
            break;
          }
          if (!row.is_child) {
            status = false;
            break;
          }
          total_amount += row.total;
        }
        this.selected_line_items[index].total = total_amount;
      }
    },
    auto_adjust() {
      let total = toBtxNumber(this.total);
      if (total > 0) {
        let adjustment = toBtxFixed(total / 10) * 10 - total;
        this.adjustment = toBtxFixed(adjustment);
        this.$nextTick(() => {
          this.calculate_total();
        });
      }
    },
    get_col_style(param, child, second_child) {
      let width = 50;
      if (param == "product") {
        if (!this.show_price) {
          width = 70;
        } else if (this.show_price) {
          width = 40;
          if (this.discount_level == "transaction") {
            width = 50;
          }
        }
      }
      if (second_child) {
        return { width: `${width - 10}%` };
      }
      return { width: child ? `${width - 5}%` : `${width}%` };
    },
    calculate_total() {
      let d_type = this.discount_level;

      this.subtotal = 0;
      this.discount = 0;
      this.gst = 0;
      this.gst_amount = 0;

      for (let i = 0; i < this.selected_line_items.length; i++) {
        if (this.selected_line_items[i].sum_rows) {
          continue;
        }
        let total = this.accountingToFixed(
          toBtxNumber(this.selected_line_items[i].quantity) *
            toBtxNumber(this.selected_line_items[i].rate)
        );
        if (total <= 0) {
          continue;
        }
        let discount = 0;
        if (d_type == "line_item") {
          if (this.selected_line_items[i].discount_type == 1) {
            discount = toBtxNumber(this.selected_line_items[i].discount_value);
          }
          if (this.selected_line_items[i].discount_type == 2) {
            discount = this.accountingToFixed(
              (toBtxNumber(total) *
                toBtxNumber(this.selected_line_items[i].discount_value)) /
                100
            );
          }
        }
        this.selected_line_items[i].total =
          isNaN(total) || !total
            ? 0
            : toBtxNumber(total) - toBtxNumber(discount);
        this.subtotal = this.accountingToFixed(
          toBtxNumber(this.subtotal) +
            toBtxNumber(this.selected_line_items[i].total)
        );
      }

      let subtotal = this.subtotal;

      if (d_type == "transaction") {
        this.discount = 0;
        if (this.discount_type == 1) {
          this.discount = toBtxNumber(this.discount_value);
        }
        if (this.discount_type == 2) {
          if (this.discount_value > 100) {
            this.discount_value = 100;
          }
          this.discount = this.accountingToFixed(
            (toBtxNumber(subtotal) * toBtxNumber(this.discount_value)) / 100
          );
        }
      }

      subtotal = toBtxNumber(subtotal) - toBtxNumber(this.discount);

      // custom discount

      if (d_type == "transaction") {
        this.ctx_discount = 0;
        if (this.ctx_discount_type == 1) {
          this.ctx_discount = toBtxNumber(this.ctx_discount_value);
        }
        if (this.ctx_discount_type == 2) {
          if (this.ctx_discount_value > 100) {
            this.ctx_discount_value = 100;
          }
          this.ctx_discount = this.accountingToFixed(
            (toBtxNumber(subtotal) * toBtxNumber(this.ctx_discount_value)) / 100
          );
        }
      }

      subtotal = toBtxNumber(subtotal) - toBtxNumber(this.ctx_discount);

      if (this.tax_active) {
        this.gst_amount = subtotal;
        this.gst = this.accountingToFixed(
          (toBtxNumber(subtotal) * toBtxNumber(this.lGST)) / 100
        );
      }

      subtotal = toBtxNumber(subtotal) + toBtxNumber(this.gst);

      this.total = this.accountingToFixed(
        toBtxNumber(subtotal) + toBtxNumber(this.adjustment)
      );
      this.emit_values();
    },
    update_line_item(param) {
      if (this.append_child) {
        let child_index = toBtxNumber(this.child_index);
        for (let item of param) {
          item.is_child = !this.is_second_child;
          item.is_second_child = this.is_second_child;
          child_index = child_index + 1;
          this.selected_line_items = this.arrayInsert(
            this.selected_line_items,
            child_index,
            item
          );
        }
        if (this.is_second_child) {
          this.selected_line_items[
            toBtxNumber(this.child_index)
          ].has_child = false;
          this.selected_line_items[
            toBtxNumber(this.child_index)
          ].has_second_child = true;
        } else {
          this.selected_line_items[
            toBtxNumber(this.child_index)
          ].has_child = true;
          this.selected_line_items[
            toBtxNumber(this.child_index)
          ].has_second_child = false;
        }
      } else {
        for (let item of param) {
          if (this.product_location == "top") {
            this.selected_line_items.unshift(item);
          } else {
            this.selected_line_items.push(item);
          }
        }
      }

      this.calculate_total();

      this.$nextTick(() => {
        this.emit_values();
        this.product_dialog = false;
        this.append_child = false;
        this.is_second_child = false;
        this.product_type = null;
        this.product_location = null;
        this.child_index = null;
      });
    },
    add_child(index, type, is_child) {
      if (type == "header") {
        this.add_header(index);
      } else if (type == "other") {
        this.is_second_child = is_child;
        this.add_other(index);
      } else {
        this.append_child = true;
        this.dialog_key = Number(new Date());
        this.is_second_child = is_child;
        this.child_index = index;
        if (type == "door") {
          this.product_type = type;
          this.product_dialog = true;
        } else if (type == "part") {
          this.product_type = type;
          this.product_dialog = true;
        } else if (type == "service") {
          this.product_type = type;
          this.product_dialog = true;
        }
      }
    },
    emit_values() {
      this.$nextTick(() => {
        this.$store.dispatch(SET_CALCULATION, {
          ctx_discount_label: this.ctx_discount_label,
          ctx_discount_value: this.ctx_discount_value,
          ctx_discount_type: this.ctx_discount_type,
          ctx_discount: this.ctx_discount,
          discount_type: this.discount_type,
          discount_value: this.discount_value,
          tax_active: this.tax_active,
          adjustment: this.adjustment,
          show_price: this.show_price,
          discount_level: this.discount_level,
          tax_value: this.lGST,
        });
        // //set empty items on duplicate
        // if(this.duplicateId){
        //   this.selected_line_items = [];
        // }

        this.$store.dispatch(SET_LINE_ITEM, this.selected_line_items);
      });
    },
    open_dialog(location, param) {
      // if(this.duplicateId){
      //   return false;
      // }

      // disable it if start date not available

      if (!this.rentalStartDate && this.moduleType == "rental") {
        ErrorEventBus.$emit(
          "update:error",
          "Please select rental start date first."
        );
        return false;
      }

      this.dialog_key = Number(new Date());
      this.product_type = param;
      this.product_location = location;
      this.product_dialog = true;
    },
    add_header(location, index) {
      if (this.duplicateId) {
        return false;
      }
      let len = this.selected_line_items.length + 1;
      if (location == "top") {
        this.selected_line_items.unshift({
          status: true,
          index: uuidv4(),
          uuid: uuidv4(),
          id: 0,
          order: len,
          type: "header",
          product_type: "header",
          is_child: false,
          has_child: false,
          has_property: false,
          product: null,
        });
      } else {
        if (typeof index == "number" && index > -1) {
          this.selected_line_items.splice(
            index,
            0,
            ...[
              {
                status: true,
                index: uuidv4(),
                uuid: uuidv4(),
                id: 0,
                order: len,
                type: "header",
                product_type: "header",
                is_child: false,
                has_child: false,
                has_property: false,
                product: null,
              },
            ]
          );
        } else {
          this.selected_line_items.push({
            status: true,
            index: uuidv4(),
            uuid: uuidv4(),
            id: 0,
            order: len,
            type: "header",
            product_type: "header",
            is_child: false,
            has_child: false,
            has_property: false,
            product: null,
          });
        }
      }
      this.$nextTick(() => {
        this.product_type = null;
        this.emit_values();
      });
    },
    add_other(location, index) {
      if (this.duplicateId) {
        return false;
      }
      let len = this.selected_line_items.length + 1;
      if (location == "top") {
        this.selected_line_items.unshift({
          status: true,
          index: uuidv4(),
          uuid: uuidv4(),
          id: null,
          image: {},
          order: len,
          serial_no: null,
          discount: 0,
          type: "other",
          is_child: false,
          is_second_child: false,
          parent_uuid: null,
          parent_second_uuid: null,
          has_child: false,
          has_second_child: false,
          sum_rows: false,
          show_price: false,
          discount_value: 0,
          custom_val: null,
          discount_type: 1,
          product: null,
          product_id: 0,
          product_type: "other",
          description: null,
          warranty: {},
          rate: 0,
          selling_cost: 0,
          quantity: 1,
          uom: "unit",
          total: 0,
        });
      } else {
        if (typeof index == "number" && index > -1) {
          /*this.selected_line_items.splice(index + 1, 0, ...[{*/
          this.selected_line_items.push({
            status: true,
            index: uuidv4(),
            uuid: uuidv4(),
            id: null,
            image: {},
            order: len,
            serial_no: null,
            discount: 0,
            type: "other",
            is_child: !this.is_second_child,
            is_second_child: this.is_second_child,
            parent_uuid: null,
            parent_second_uuid: null,
            has_child: false,
            has_second_child: false,
            sum_rows: false,
            show_price: false,
            discount_value: 0,
            custom_val: null,
            discount_type: 1,
            product: null,
            product_id: 0,
            product_type: "other",
            description: null,
            warranty: {},
            rate: 0,
            selling_cost: 0,
            quantity: 1,
            uom: "unit",
            total: 0,
          });
        } else {
          this.selected_line_items.push({
            status: true,
            index: uuidv4(),
            uuid: uuidv4(),
            id: null,
            image: {},
            order: len,
            serial_no: null,
            discount: 0,
            type: "other",
            is_child: false,
            is_second_child: false,
            parent_uuid: null,
            parent_second_uuid: null,
            has_child: false,
            has_second_child: false,
            sum_rows: false,
            show_price: false,
            discount_value: 0,
            custom_val: null,
            discount_type: 1,
            product: null,
            product_id: 0,
            product_type: "other",
            description: null,
            warranty: {},
            rate: 0,
            selling_cost: 0,
            quantity: 1,
            uom: "unit",
            total: 0,
          });
        }
      }

      this.$nextTick(() => {
        this.product_type = null;
        this.emit_values();
      });
    },
    remove_row(index) {
      this.selected_line_items.splice(index, 1);
      this.calculate_total();
      this.emit_values();
    },
  },
  components: {
    Draggable,
    LineItemDialog,
  },
  watch: {
    lEditLineItem() {
      this.selected_line_items = this.lEditLineItem;
      this.calculate_total();
    },
    lEditCalculation() {
      this.discount_type = this.lEditCalculation?.discount_type;
      this.discount_value = this.lEditCalculation?.discount_value;
      this.tax_active = this.lEditCalculation?.tax_active;
      this.adjustment = this.lEditCalculation?.adjustment;
      this.show_price = this.lEditCalculation?.show_price;
      this.discount_level = this.lEditCalculation?.discount_level;
      this.ctx_discount_label = this.lEditCalculation?.ctx_discount_label;
      this.ctx_discount_value = this.lEditCalculation?.ctx_discount_value;
      this.ctx_discount_type = this.lEditCalculation?.ctx_discount_type;
      this.calculate_total();
    },
  },

  computed: {
    ...mapGetters([
      "lGST",
      "lUOM",
      "lProcessing",
      "lEditLineItem",
      "lEditCalculation",
    ]),
  },
};
</script>
